<template>
<div id="artists" class="artists">
    <div class="m-t-nav">

        <LocationsListBig
        :dataRef="'locations/rankProfiles'"
        />

        <router-view></router-view>

        <p id="locations" class="p-t-nav">
          <LocationsList
          :dataRef="'locations/rankProfiles'"
          :listType="'profiles'"
          :alignCenter="true"
          />
        </p>

    </div>
</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'
import LocationsListBig from '@/components/artists/LocationsListBig.vue'
import LocationsList from '@/components/toplists/LocationsList.vue'

export default {
  name: 'Artists',
  components: {
    LocationsListBig,
    Playlist,
    LocationsList
  },
  data() {
	  return {
        profilesWorld: {
          users: null,
        }
	  }
  },
  mounted() {
    this.getProfilesWorld()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ])
  },
  methods:{
    getProfilesWorld() {
      rtdb.ref('rankLocations/rankProfiles/top10/World').on('value', (snapshot) => {
        this.profilesWorld.users = snapshot.val()
      })
    },
  }
  
}
</script>
<style lang="scss" scoped>
.artists {
    // min-height: 100vh;
}
</style>