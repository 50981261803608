<template>
  <div class="about">

    <DescLong/>
    <Footer/>

  </div>
</template>

<script>
import DescLong from '@/components/texts/DescLong.vue'
import Footer from '@/components/navigation/Footer.vue'

export default {
  name: 'About',
  components: {
    DescLong,
    Footer
  }
}
</script>
