<template>
  <div class="legal">

    <LegalText/>
    <Footer/>

  </div>
</template>

<script>
import LegalText from '@/components/texts/LegalText.vue'
import Footer from '@/components/navigation/Footer.vue'

export default {
  name: 'LegalPage',
  components: {
    LegalText,
    Footer
  }
}
</script>
