<template>
<div id="artists-page">
    <Music/>
    <Footer/>
</div>
</template>

<script>
import Music from '@/components/music/Music.vue'
import Footer from '@/components/navigation/Footer.vue'

export default {
  name: 'MusicPage',
  components: {
    Music,
    Footer
  }
}
</script>
