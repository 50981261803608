<template>
<div id="artists-page">
    <Artists/>
    <Footer/>
</div>
</template>

<script>
import Artists from '@/components/artists/Artists.vue'
import Footer from '@/components/navigation/Footer.vue'

export default {
  name: 'ArtistsPage',
  components: {
    Artists,
    Footer
  }
}
</script>
