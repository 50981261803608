<template>
<div id="music" class="music">
    <div class="m-t-nav">

        <LocationsListBig
        :dataRef="'locations/rankSongs'"
        />
        <!-- <LocationsListBig
        :dataRef="'genres/rankSongs'"
        /> -->

        <router-view></router-view>

        <p id="locations" class="p-t-nav">
          <LocationsList
          :dataRef="'locations/rankSongs'"
          :listType="'songs'"
          :alignCenter="true"
          />
        </p>

        <p id="genres" class="p-t-nav">
          <GenresListSmall
          :dataRef="'genres/rankSongs'"
          :listType="'songs'"
          />
        </p>


    </div>
</div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"
import Playlist from '@/components/playlists/Playlist.vue'
import LocationsListBig from '@/components/music/LocationsListBig.vue'
import LocationsList from '@/components/toplists/LocationsList.vue'
import GenresListSmall from '@/components/toplists/GenresListSmall.vue'

export default {
  name: 'Music',
  components: {
    LocationsListBig,
    Playlist,
    LocationsList,
    GenresListSmall
  },
  data() {
	  return {
        profilesWorld: {
          users: null,
        }
	  }
  },
  mounted() {
    this.getProfilesWorld()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ])
  },
  methods:{
    getProfilesWorld() {
      rtdb.ref('rankLocations/rankProfiles/top10/World').on('value', (snapshot) => {
        this.profilesWorld.users = snapshot.val()
      })
    },
  }
  
}
</script>
<style lang="scss" scoped>
.music {
    // min-height: 100vh;
}
</style>