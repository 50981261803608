<template>
<div id="desc-long" class="big-m-b m-t-nav">
    <h2 class="wrap tt-up p-l p-r ta-j">
        <div>
is a music exploration platform designed to attract and determine the best independent music and musicians in your local neighborhood, in your city, in your country and on planet Earth.
        </div>

        <div class="t-i">
<span class="grey">The ranking algorithm</span> is based on a dynamic ratio determined by how many total interactions divided by how many unique plays a song or artist currently have accumulated. This to create an egalitarian chart system based on the algorithmic vote of the community.
        </div>

        <div class="t-i">
<span class="grey">Filtering</span> on area and genre also provides a publishing mechanism for artists to autonomously grow from a local to global audience.
        </div>


        <div class="t-i">
<span class="grey">Manifesto: </span>
<span class="grey fs-i">1—</span>Create, dance, make love. 
<span class="grey fs-i">2—</span>Express yourself (unrelentlessly). 
<span class="grey fs-i">3—</span>Never compromise. 
<span class="grey fs-i">4—</span>Inquire intently. 
<span class="grey fs-i">5—</span>Explore boldly. 
<span class="grey fs-i">6—</span>Create creatively. 
<span class="grey fs-i">7—</span>F*** time. 
<span class="grey fs-i">8—</span>F*** age. 
<span class="grey fs-i">9—</span>F*** normal. 
<span class="grey fs-i">10—</span>Feelings, ideas, ecstasy (over all). 
<span class="grey fs-i">11—</span>Struggle is progress. 
<span class="grey fs-i">12—</span>Make your mama proud. 
        </div>

        <div class="t-i">
<span class="grey">Created by: </span><a class="text-link" href="https://mesh-innovation.com" target="_blank">MESH Innovation</a> - a web development studio, specialized in building and designing products for the internet.
        </div>

        <div class="t-i">
<span class="grey">Don't be a stranger! </span>Algorhythm is built to service all music lovers alike. If you have any ideas, thoughts or feedback please reach out to us. Algorhythm will always be a work in progress and we want to ideate, design and build its future together with you. Please come and say hello to us in any of our channels.
        </div>       
<div class="t-i"><a class="text-link" href="https://www.instagram.com/algorhythm.world/" target="_blank">Instagram</a></div>
<div class="t-i"><a class="text-link" href="https://discord.gg/R8HXGjPDFb" target="_blank">Discord</a></div> 
<div class="t-i"><a class="text-link" href="https://communityinviter.com/apps/algorhythmcorp/algorhythm" target="_blank">Slack</a></div>
<div class="t-i"><a class="text-link" href="https://twitter.com/AlgorhythmFM" target="_blank">Twitter</a></div>
<div class="t-i"><a class="text-link" href="mailto:email@mesh-in.com" target="_blank">E-mail</a></div>  
  
<!-- <div class="rights">Copyright © 2019 Meshin AB. All Rights Reserved.</div> -->


      </h2>
  </div>
</template>

<script>
export default {
    name: 'DescLong',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#desc-long {
    .wrap {
        background-color: $brand-bg;
    }
}
</style>