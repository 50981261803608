<template>
<div id="genres-list">
    
  <!-- {{listData}} -->

  <div class="genres-wrap tt-up p d-f" style="font-weight: 400;" v-show="dataRef === 'genres/rankSongs'">  
    <div class="genre">
        <router-link class="" :to="{ path: `/music/genre-${firstEntry.title}` }" :class="{ underline: isActive(`/music/genre-${firstEntry.title}`) }">
        {{ firstEntry.title }}
        <span class="grey ws-pre">({{firstEntry.count}})</span>
        </router-link>
    </div>
    <div class="genre active-genres">
        <div v-for="(x, key) in listData" :key="x.id">
            <router-link class="" :to="{ path: `/music/genre-${key}` }" :class="{ underline: isActive(`/music/genre-${key}`) }">{{key}}<span class="grey ws-pre"> ({{x.count}})</span></router-link>
        </div>
    </div>
    <div class="genre">
        <div v-for="(x, key) in emptyListData" :key="x.id">
            <a class="grey" style="text-decoration: none; cursor: default;">{{key}}<span class="grey ws-pre"> (0)</span></a>
        </div>
    </div>
  </div>

</div>
</template>

<script>
import { rtdb } from '@/db.js'

export default {
  name: 'GenresListSmall',
  props: [
      'dataRef'
  ],
  data() {
	  return {
          listData: {},
          emptyListData: {},
          firstEntry: ''
	  }
  },
  mounted() {
      this.getList()
  },
  methods: {
    getList() {
      rtdb.ref(this.dataRef).once('value', (snapshot) => {
        let val = snapshot.val()
        this.listData = val
        this.getEmptyList(val)  

        let entries = Object.entries(val)[0]
        const obj = {
            title: entries[0],
            count: entries[1].count
        }
        this.firstEntry = obj
      })
    },
    getEmptyList(x) {
      rtdb.ref('genres/dropdown').once('value', (snapshot) => {  
        const val = snapshot.val()
        Object.keys(x).forEach(i => {
            delete val[i]
        })
        this.emptyListData = val
//TO-DO: remove duplicate keys       
      })
    },
    isActive(x, xx) {
        return (this.$route.path === x || this.$route.path === xx) ? true : false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#genres-list {
    .genres-wrap {
        @media #{$mobile} {
            flex-direction: column;
            text-align: center;
            font-size: 1.5em;
        }
        .genre {
            flex-basis: 20%;
            flex-grow: 1;
        }
        .active-genres {
            div:first-child {
                display: none;
            }
        }
    }
}
</style>