<template>
<div id="locations-list-big" class="p-l p-r big-m-t">

  <h2 class="tt-up">  

    <div class="d-f fd-c jc-c ai-c">
      <div class="filter-wrap d-f ai-c">

        <span class="grey ws-pre o-5">Filter by: </span>

        <a href="#locations" class="d-f ai-c m-l text-link">         
          <h3 class="d-f jc-c">
            {{getUrl}}
          </h3>
          <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </a>

        <span class="grey m-r o-5 m-l hide-mob">/</span>
        <div class="d-f ai-c">  
            <span class="grey">Genre</span>
            <IconBase w="24" h="24" color="grey"><IconArrowDown/></IconBase>
        </div>
      </div>
    </div>

  </h2>

</div>
</template>

<script>
import { rtdb } from '@/db.js'
import IconBase from '@/assets/icons/IconBase.vue'
import IconArrowDown from '@/assets/icons/IconArrowDown.vue'
import LocationsList from '@/components/toplists/LocationsList.vue'

export default {
  name: 'LocationsListBig',
  props: [
      'dataRef'
  ],
  components: {
      IconBase,
      IconArrowDown,
      LocationsList
  },
  data() {
	  return {
          listData: {},
	  }
  },
  mounted() {
      this.getProfilesList()
  },
  computed: {
    getUrl() {
      return this.$route.params.id
    }
  },
  methods: {
    getProfilesList() {
      rtdb.ref(this.dataRef).once('value', (snapshot) => {
        this.listData = snapshot.val()
      })
    },
    isActive(x, xx) {
        return (this.$route.path === x || this.$route.path === xx) ? true : false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#locations-list-big {
  .filter-wrap {
  @media #{$mobile} { 
    flex-direction: column;
  }
  } 
}

</style>